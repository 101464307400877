<template>
  <div class="overflow-auto">
    <div
        v-for="stage in stages"
        v-bind:key="stage.ID"
        class="d-flex flex-column mt-3"
    >
      <div class="d-flex text-center text-uppercase py-2">
        <div class="flex-shrink-1 w-100" style="max-width: 60px;"></div>
        <div class="w-100 pl-2 h6 " :class="dataSport.textClass" v-if="$i18n.locale === 'ru'">
          {{ stage.title }}
        </div>
        <div class="w-100 pl-2 h6" :class="dataSport.textClass" v-else>{{ stage.title_eng }}</div>
      </div>
      <StageGames :games="stage.games" :dataSport="dataSport"></StageGames>
    </div>
  </div>
</template>

<script>
export default {
  name: "TourStages",
  props: ["stages","dataSport"],
  components: {
    StageGames: () => import("@/components/blocks/counter_strike/StageGames.vue")
  }
};
</script>

<style scoped></style>
